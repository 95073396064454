export const managedEvent = (payload) => {
  return `{
    me {
      courses {
        type
        name
        id
        layouts {
          id
          name
          latestVersion {
            id
          }
        }
      }
    }
    event(eventId: "${payload}") {
      tour {
        id
        name
        isPublished
        type {
          name
          type
        }
        events {
          id
        }
      }
      pdgaType {
        name
        type
      }
      id
      name
      registrationMethod
      status
      maxSpots
      groupSize
      playersCount
      checkInStartsAt
      checkInEndsAt
      startDate
      endDate
      registrationOpenAt
      isRegistrationOpen
      isResultSigningEnabled
      isVerifyMode
      playersCount
      registrationsCount
      singles
      teamSize
      defaultToTeamName
      players {
        dnf {
          isDnf
        }
        dns {
          isDns
        }
      }
      registrations {
        ... on Registration {
          id
          status
        }
      }
      rounds {
        id
        status
        isDeletable
        pools {
          id
          position
          status
          date
          isDeletable
          areGroupsPublic
          startMethod
          playFormat
          groups {
            id
            status
            position
            startsAt
            startHole {
              id
              number
              name
            }
            marshall {
              id
              firstName
              lastName
            }
            playerConnectionsV2 {
              ... on GroupTeamPlayerConnection {
                id
                position
                hasSignedResult
                teamPlayerId
                teamPlayer {
                  id
                  hasPaid
                  dnfDns {
                    dnfDns
                    dnfDnsErType
                    reportedById
                  }
                  division {
                    id
                    name
                    type
                  }
                  team {
                    id
                    name
                    teamPlayers {
                      user {
                        firstName
                        lastName
                      }
                    }
                  }
                }
              }
              ... on GroupPlayerConnection {
                hasSignedResult
                id
                position
                groupId
                player {
                  id
                  isWildcard
                  hasPaid
                  division {
                    id
                    type
                    name
                  }
                  dnf {
                    isDnf
                  }
                  dns {
                    isDns
                  }
                  user {
                    id
                    firstName
                    lastName
                    profile {
                      pdgaNumber
                      pdgaRating
                      club
                    }
                  }
                }
              }
            }
            id
            position
            status
          }
          layoutVersion {
            id
            holes {
              par
              id
              name
              number
            }
            layout {
              name
              type
              latestVersion {
                id
                holes {
                  par
                  id
                  name
                  number
                }
              }
              course {
                id
                name
              }
            }
          }
        }
      }
    }
  }
  `
}

export const favouriteEvents = `
  {
    me {
      favouriteEvents {
        id
        event {
          id
          name
          status
          isRegistrationOpen
          registrationOpenAt
          checkInStartsAt
          checkInEndsAt
          isVerifyMode
          isResultSigningEnabled
          startDate
          endDate
          pdgaType {
            name
            type
          }
          city {
            name
            country
          }
          rounds {
            id
            pools {
              id
              date
            }
          }
          tour {
            name
            type {
              name
              type
            }
          }
        }
      }
    }
  }
`
export const favouriteEventIds = `
  {
    me {
      favouriteEvents {
        id
        event {
          id
        }
      }
    }
  }
`

export const userTourPasses = `
  {
    me {
      tourPasses {
        id
        payment {
          id
          isRefunded
        }
        division {
          id
        }
        tour {
          id
        }
      }
    }
  }
`
export const activeScorecards = `
  {
    me {
      marshalls(status: OPEN) {
        id
        pool {
          round {
            event {
              name
              tour {
                name
              }
            }
          }
        }
      }
      teamGroups(filter: {operator: OR, query: [{groupStatus: OPEN}, {operator: AND, groupStatus: DONE, poolStatus: OPEN}]}) {
        id
        playerConnectionsV2 {
          ... on GroupTeamPlayerConnection {
            id
            teamPlayer {
              id
            }
          }
        }
      }
      groups(filter: {operator: OR, query: [{operator: AND, groupStatus: OPEN, poolStatus: OPEN}, {operator: AND, groupStatus: DONE, poolStatus: OPEN}]}) {
        id
        playerConnectionsV2 {
          ... on GroupPlayerConnection {
            player {
              userId
            }
          }
        }
        marshall {
          id
          profile {
            id
          }
        }
      }
    }
  }
`

export const groupEditor = (payload) => {
  return `
    {
      event(eventId:"${payload}"){
        defaultToTeamName
        divisions{
          type
          name
          id
        }
        registrationMethod
        isResultSigningEnabled
        singles
        teams{
          id
          teamId
          eventTotal
          divisionId
          division{
            type
            name
          }
          dnfDns{
            dnfDns
          }
          team{
            id
            name
            teamPlayers{
              user{
                id
                firstName
                lastName
              }
            }
          }
        }
        players{
          id
          eventTotal
          division{
            type
          }
          user{
            id
            firstName
            lastName
            profile{
              pdgaNumber
              pdgaRating
            }
          }
          dnf{
            isDnf
          }
          dns{
            isDns
          }
        }
        registrations{
          ... on TeamRegistration{
            id
            status
            createdAt
            statusUpdatedAt
            message
            division{
              id
              type
              name
            }
            team{
              id
              name
              teamPlayers{
                id
                user{
                  id
                  firstName
                  lastName
                  profile{
                    club
                    pdgaNumber
                    pdgaRating
                  }
                }
              }

            }
            division{
              id
              name
            }
          }
          ... on Registration {
            id
            status
            hasPaid
            createdAt
            division{
              type
              name
              id
            }
            user{
              id
              firstName
              lastName
              profile{
                pdgaNumber
                pdgaRating
              }
            }
            player{
              id
            }
          }
        }
        rounds{
          id
          status
          pools{
            id
            position
            status
            date
            isDeletable
            areGroupsPublic
            startMethod
            groups{
              id
              status
              position
              startsAt
              startHole{
                id
                number
                name
              }
              marshall{
                id
                firstName
                lastName
              }
              playerConnectionsV2{
                ... on GroupTeamPlayerConnection{
                  id
                  position
                  teamPlayerId
                  groupId
                  roundTotal
                  teamPlayer{
                    id
                    hasPaid
                    dnfDns{
                      dnfDns
                      dnfDnsErType
                      reportedById
                    }
                    division{
                      id
                      name
                      type
                    }                
                    team{
                      id
                      name
                      teamPlayers{
                        user{
                          firstName
                          lastName
                        }
                      }
                    }
                  }
                }
                ... on GroupPlayerConnection{
                  hasSignedResult
                  id
                  position
                  groupId
                  roundTotal
                  player{
                    id
                    isWildcard
                    hasPaid
                    division{
                      id
                      type
                      name
                    }
                    dnf{
                      isDnf
                    }
                    dns{
                      isDns
                    }
                    registration{
                      id
                    }
                    user{
                      id
                      firstName
                      lastName
                      profile{
                        pdgaNumber
                        pdgaRating
                        club
                      }
                    }
                  }
                }
              }
              id
              position
              status
            }
            layoutVersion{
              holes{
                par
                id
                name
                number
              }
              layout{
                name
                course{
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  `
}

export const managerMenu = (payload) => {
  return `
    {
      event(eventId:"${payload}"){
        id
        name
        singles
        defaultToTeamName
        teamSize
        status
        isPublished
        isRegistrationOpen
        registrationOpenAt
        registrationsCount
        playersCount
        startDate
        endDate
        subscriptionTier
        registrationMethod
        waitinglistType{
          type
        }
        divisions{
          id
          type
          name
        }
        registrationsCountPerDivision{
          totalAccepted
          totalWaiting
          totalCancelled
          countPerDivision{
            divisionId
            totalCount
            acceptedCount
            waitingCount
            cancelledCount
          }
        }
        pdgaType{
          name
        }
        rounds{
          id
          status
          pools{
            id
            name
            status
          }
        }
        tour{
          id
          isPublished
          isPrivate
          eventsCount
          paymentType
        }
      }
    }
  `
}
